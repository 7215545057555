export default function Development() {
  return (
    <div
      className="flex justify-center items-center text-5xl"
      style={{
        height: "58vh",
        fontFamily: "HelpUsGiambattista",
        color: "rgb(159, 0, 63)",
      }}
    >
      This feature is currently under development.
    </div>
  );
}
