import React from "react";

export default function OurFavoritesBanner() {

  return (
    <div className="pb-6 mt-8 pt-8" >
      <h2 className="text-2xl font-bold text-center">Our Favorite Wines of the Week</h2>
    </div>
  );
}
